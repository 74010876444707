<template>
  <div>
    <div class="search">
      <div class="searchFl">
        <portSelect
          v-model="searchQuery.desc"
          placeTitle="请输入目的港"
          clearable
          @input="dest_port_input"
        />

        <el-select style="margin-left:20px" v-model="searchQuery.type" clearable size="small" placeholder="整拼类型">
          <el-option v-for="(item,index) in typeArr" :key="index" :value="item.value" :label="item.label"></el-option>
        </el-select>
        
        <el-date-picker
          :clearable='true'
          style="margin-left:20px;"
          v-model="searchQuery.time"
          type="daterange"
          size="small"
          range-separator="-"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        >
        </el-date-picker>

        <el-input style="width:230px;margin-left:20px" clearable v-model="searchQuery.name" size="small" placeholder="请输入客户名称"></el-input>
      </div>
      <div>
        <el-button size="small" type="primary" @click="searchChange"
          >查询</el-button
        >
      </div>
    </div>
    <div class="line"></div>
    <div class="tableBlock">
      <el-table
        :data="tableData"
        style="width: 100%"
        :height="tableHeight"
        v-tableHeight="{ bottomOffset: 74 }"
        :tableLoading="loading"
        @sort-change="tableSort"
      >
        <el-table-column
          label="港口信息"
          width="300"
          :show-overflow-tooltip="true"
        >
          <div slot-scope="scope" class="protMsg">
            <div class="from">
              <p class="textOverflow" style="color:#91be42">{{ scope.row.from_port | textFormat }}</p>

              <p class="textOverflow">{{ scope.row.from_port_zh | textFormat }}</p>
            </div>
            <div class="jt" style="color:#7D8695">>>></div>
            <div class="dest">
              <p class="textOverflow"  style="color:#91be42">{{ scope.row.dest_port | textFormat }}</p>
              <p class="textOverflow">{{ scope.row.dest_port_zh | textFormat }}</p>
            </div>
          </div>
        </el-table-column>
        <el-table-column label="中转港" :show-overflow-tooltip="true">
          <div slot-scope="scope">
            {{
              scope.row.trans_port_en ||
              scope.row.trans_port ||
              "直达" | textFormat
            }}
          </div>
        </el-table-column>
        <el-table-column label="整拼类型" :show-overflow-tooltip="true">
          <div slot-scope="scope">
            {{ scope.row.freight_type==1 ? "整箱" : "拼箱" | textFormat }}
          </div>
        </el-table-column>
     
        <el-table-column label="本月分享客户数" sortable='custom' prop="month_share_num" :show-overflow-tooltip="true">
          <div slot-scope="scope">
            {{ scope.row.month_share_num*1 }}
          </div>
        </el-table-column>
        <el-table-column label="分享客户数" sortable='custom' prop="num_share" :show-overflow-tooltip="true">
          <div slot-scope="scope">
            {{ scope.row.num_share*1 }}
          </div>
        </el-table-column>
        <el-table-column label="" :show-overflow-tooltip="true" width="100">
          <div slot-scope="scope">
            <el-button type="text" @click="gotoDetail(scope.row)"
              >查看明细</el-button
            >
          </div>
        </el-table-column>
      </el-table>
    </div>
    <div class="paginationBlock">
      <el-pagination
        @current-change="handleChange"
        :page-size="10"
        layout="total, prev, pager, next"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import portSelect from "~ymp/components/common/portSelect";

export default {
  name: "shareRecords",
  components: { portSelect },
  data() {
    return {
      searchQuery:{
        desc:"",
        type:"",
        time:"",
        name:"",
        prop:"month_share_num",
        order:"descending"
      },
      loading: false,
      total: 0,
      currentPage: 1,
      tableHeight: 100,
      tableData: [],
      typeArr:[
        {value:"",label:"全部"},
        {value:1,label:"整箱"},
        {value:2,label:"拼箱"},
      ],
      query:{
        time:[],
        name:""
      }
    };
  },
  mounted() {
    this.$log({
      type: 2,
      topic: "ymp.workbench",
      id: "ymp.workbench.share",
    });
    let start_date = this.$moment().subtract(6, 'days').startOf('day')
    let end_date = this.$moment().endOf('day')
    this.searchQuery.time=[start_date,end_date]
    this.getList();
  },
  methods: {
    tableSort(row){
      console.log(row);
      this.searchQuery.prop=row.prop
      this.searchQuery.order=row.order
      this.currentPage=1
      this.getList();
    },
    gotoDetail(row){
        let query={
          freight_id: row.freight_id,
          name:this.query.name,
          freight_type:row.freight_type,
          from_port:row.from_port_id,
          trans_port:row.trans_port_id,
          dest_port:row.dest_port_id,
        }
        
        if(this.query.time.length){
          query.start_time=this.query.time[0]
          query.end_time=this.query.time[1]
        }
        

        let url = this.$router.resolve({
          path: "/console/myoffer/shareRecords_details",// 
          query,
        });
        window.open(url.href, "_blank");
    },
    dest_port_input(val) {
      if (val === "") val = null;
      let num = Number(val);
      if (!isNaN(num) || val === "") {
        this.searchQuery.desc = val;
      }
    },
    handleChange(e) {
      this.currentPage = e;
      this.getList();
    },
    async getList() {
      this.loading = true;
      let params = {
        user_id: this.USER_INFO.id,
        limit: 10,
        start: this.currentPage - 1,
        dest_port:this.searchQuery.desc,
        freight_type:this.searchQuery.type,
        client:this.searchQuery.name,
        order:this.searchQuery.order,
        prop:this.searchQuery.prop,
      };
      this.query.name=this.searchQuery.name
      if(this.searchQuery.time&&this.searchQuery.time.length){
         params.start_time=this.$moment(this.searchQuery.time[0]).startOf('day').unix()
         params.end_time=this.$moment(this.searchQuery.time[1]).endOf('day').unix()
         this.query.time=[this.$moment(this.searchQuery.time[0]).unix(),this.$moment(this.searchQuery.time[1]).unix()]
      }
      let result = await this.$store.dispatch( //
        "baseConsole/freight_share_info",
        params
      );
      this.loading = false;
      if (result && result.success) {
        this.tableData =result.data;
        this.total = result.count;
      }
    },
    searchChange() {
      this.$log({
        type: 1,
        topic: "ymp.workbench",
        id: "ymp.workbench.share.search",
        content: JSON.stringify({
          pod: this.searchQuery.desc || '',
          start_time: this.searchQuery.time&&this.searchQuery.time.length ? this.$moment(this.searchQuery.time[0]).startOf('day').unix() : '',
          end_time: this.searchQuery.time&&this.searchQuery.time.length ? this.$moment(this.searchQuery.time[1]).endOf('day').unix() : '',
          type: this.searchQuery.type || '',
          name: this.searchQuery.name || '',
        }),
      });
      this.tableData = [];
      this.currentPage = 1;
      this.getList();
    },
  },
  filters:{
    gp_msg(arr){
      let str=''
      arr.forEach(item=>{
        if(item.number){
          let l=item.name+','
          str+=l
        }
      })
      str=str.substring(0,str.length-1)
      return str
    }
  }
};
</script>
<style scoped lang="less">
/deep/ .el-range-separator{
  display:inline-block;
  width: 30px;
}
.protMsg {
  display: flex;
  justify-content: space-between;
  .from {
    width: 100px;
    p{
      width: 100px;
    }
  }
  .jt {
    width: 50px;
    color: #027fff;
  }
  .dest {
    width: 100px;
    p{
      width: 100px;
    }
  }
}
.search {
  display: flex;
  width: 100%;
  align-items: center;
  padding: 10px;
  justify-content: space-between;

  .searchFl {
    width: 90%;
    display: flex;
    align-items: center;

    .searchInput {
      width: 15%;
      padding: 0 6px;
    }
  }
}
.line {
  height: 1px;
  width: 100%;
  background: #ccc;
  margin-bottom: 20px;
}
.ship_box{
  display: flex;
  flex-direction: column;
  img{
    width: 25px;
    height: 25px;
  }
}
</style>